import {Link} from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import {Waypoint} from 'react-waypoint'
import pic01 from '../assets/images/antoine.png'
import pic02 from '../assets/images/pauline.png'
import doctolib from '../assets/images/doctolib.png'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({stickyNav: false}))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({stickyNav: true}))
  }

  render() {
    return (<Layout>
      <Helmet defer={false} htmlAttributes={{
          lang: 'fr'
        }}>
        <title>Cabinet de Podologie du Sport de Balma</title>
        <meta name="description" content="Pédicure podologue situé à Balma, proche de Toulouse, au sein d'un cabinet paramédical regroupant des kinésithérapeutes du sport ainsi que des médecins pour une prise en charge pluridisciplinaire du patient."/>
      </Helmet>

      <Header/>

      <Waypoint onEnter={this._handleWaypointEnter} onLeave={this._handleWaypointLeave}></Waypoint>
      <Nav sticky={this.state.stickyNav}/>

      <div id="main">

        <section id="contact" className="main special">
          <ul className="features">
            <li>
              <h2>Antoine LE BIHAN</h2>
              <p>
                <Link to="https://www.doctolib.fr/pedicure-podologue/balma/antoine-le-bihan?pid=practice-87554" className="button special doctolib">
                  Prendre RDV sur <img src={doctolib} alt=""/>
                </Link>
              </p>
            </li>
            <li>
              <h2>Pauline FONTORBES</h2>
              <p>
                <Link to="https://www.doctolib.fr/pedicure-podologue/balma/pauline-fontorbes?pid=practice-128660" className="button special doctolib">
                  Prendre RDV sur <img src={doctolib} alt=""/>
                </Link>
              </p>
            </li>
          </ul>
          <footer className="major">
            <p>podologue.sport.balma@gmail.com</p>
            <p>06 21 79 51 31</p>
          </footer>
        </section>

        <section id="equipe" className="main special">
          <header className="major">
            <h2>L'équipe</h2>
          </header>
          <p>Mr Antoine Le Bihan et Mme Pauline Fontorbes travaillent au sein d'un cabinet paramédical regroupant des kinésithérapeutes du sport ainsi que des médecins pour une prise en charge pluridisciplinaire du patient.</p>

          <div className="spotlight">
            <span className="image right">
              <img src={pic01} alt=""/>
            </span>
            <div className="content left">
              <h3><b>Antoine LE BIHAN</b></h3>
              <p>
                Pédicure Podologue DE<br/>Podologue du sport DU<br/>Préférences motrices naturelles Volodalen®<br/>Réflexes archaïques
              </p>
            </div>
          </div>

          <div className="spotlight">
            <span className="image left">
              <img src={pic02} alt=""/>
            </span>
            <div className="content right">
              <h3><b>Pauline FONTORBES</b></h3>
              <p>
                Pédicure Podologue DE
              </p>
            </div>
          </div>
        </section>

        <section id="horaires" className="main special">
          <header className="major">
            <h2>Horaires</h2>
          </header>
          <ul class="features">
            <li>
              <p>Lundi au Vendredi<br/>9h - 20h</p>
            </li>
          </ul>
        </section>

        <section id="tarifs" className="main special">
          <header className="major">
            <h2>Tarifs</h2>
          </header>

          <table className="price">
            <tr>
              <td>Soin de pédicurie</td>
              <td>35 €</td>
            </tr>
            <tr>
              <td>Soin de pédicurie à domicile</td>
              <td>40 €</td>
            </tr>
            <tr>
              <td>Examen clinique et confection de semelles orthopédiques</td>
              <td>160 €</td>
            </tr>
          </table>
        </section>

        <section id="acces" className="main special">
          <header className="major">
            <h2>Accès</h2>
          </header>
          <i class="icon fa-wheelchair"></i>&nbsp;accessible en fauteuil&nbsp;&bull;&nbsp;<i class="icon fa-car"></i>&nbsp;parking gratuit
          <p id="gmaps">
            <iframe title="Plan Google Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d722.0459048581191!2d1.4928671292489355!3d43.623537069505474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12aebd3d9123e647%3A0xf1da3b85b2ac1a8c!2sANTOINE%20LE%20BIHAN%20PODOLOGUE%20SPORT!5e0!3m2!1sfr!2sfr!4v1620769413093!5m2!1sfr!2sfr" allowfullscreen="" loading="lazy"></iframe>
          </p>
        </section>
      </div>
    </Layout>)
  }
}

export default Index
