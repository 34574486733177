import React from 'react'

import logo from '../assets/images/logo-b.png';

const Header = (props) => (<header id="header" className="alt">
  <span className="logo"><img src={logo} alt=""/></span>
  <h1>Cabinet de Podologie du Sport de Balma</h1>
  <div className="subtitle-container">
    <span className="subtitle-item">Pédicure Podologue DE</span>
    <span className="subtitle-separator">&nbsp;-&nbsp;</span>
    <span className="subtitle-item">Podologue du sport DU</span>
  </div>
</header>)

export default Header
